<picture>
  <source type="image/webp" srcset="./assets/img/face.webp">
  <source type="image/jpeg" srcset="./assets/img/face.jpg">
  <img src="./assets/img/face.jpg" alt="face">
</picture>
<h2>
  Wako
</h2>
<div id="info">
  <p>
    Hello, I'm <span>Rodrigo Patiño</span> - net artist, full stack developer and mostly a human disaster.
  </p>
  <p>
    I code, setup Linux servers and deploy software to the web.
  </p>
  <p>
    I work mostly with Go, Node.js, Angular, and relational databases. I used to code in PHP 😳 and I know a little bit of Rust, Elixir and Scala (along with other programming languages).
  </p>
  <p>
    You can drop me an <a href="mailto:contacto.rodrigo.patino@gmail.com?subject=Hello There!&amp;body=Hi Rodrigo, so I was looking at your website and...">email</a> or add me on <a href="https://www.linkedin.com/in/rodrigo-patino-dev/" target="_blank" rel="noopener">LinkedIn</a>.
  </p>
</div>
