<h2>Info</h2>

<div id="tabla">
  <div class="fila" *ngFor="let spec of specs">
    <div class="columna">{{spec.nombre}}</div>
    <div class="columna">
      <ng-container *ngIf="spec.url; else desc">
        <a [href]="spec.url" target="_blank" rel="noopener">
          {{spec.descripcion}}
        </a>
      </ng-container>
      <ng-template #desc>
        {{spec.descripcion}}
      </ng-template>
    </div>
  </div>
</div>