<footer>
  <p>© {{ ano }}</p>
  <p>4a757374</p>
  <p>20612077</p>
  <p>68697370</p>
  <p>65722e20</p>
  <p>49206865</p>
  <p>61722069</p>
  <p>7420696e</p>
  <p>206d7920</p>
  <p>67686f73</p>
  <p>742e</p>
</footer>
