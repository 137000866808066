<header>
  <nav>
    <ul>
      <li>
        <a routerLink="/">/</a>
      </li>
      <li>
        <a routerLink="/not-me">/not-me</a>
      </li>
      <li>
        <a routerLink="/cv">/cv</a>
      </li>
    </ul>
  </nav>
</header>
