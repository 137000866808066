<main>

  <section id="about">
    <h1>Rodrigo Patiño</h1>
    <h2>Full-stack Developer</h2>
    <h3>Ciudad de México</h3>

    <address>
      <a href="mailto:contacto.rodrigo.patino@gmail.com?subject=Hello There!&amp;body=Hola, Rodrigo. Estaba checando tu CV..."><fa-icon [icon]="faEnvelope"></fa-icon> contacto.rodrigo.patino@gmail.com</a>
      <a target="_blank" rel="noopener" href="https://www.linkedin.com/in/rodrigo-patino-dev/"><fa-icon [icon]="faLinkedin"></fa-icon> LinkedIn</a>
      <a target="_blank" rel="noopener" href="https://twitter.com/akkonomi"><fa-icon [icon]="faTwitter"></fa-icon> Twitter</a>
      <a target="_blank" rel="noopener" href="https://github.com/4k1k0"><fa-icon [icon]="faGithub"></fa-icon> Github</a>
    </address>

    <article>
      <p>Soy un desarrollador de software autodidacta con más de 5 años de experiencia. He participado en diferentes equipos donde se me ha reconocido por mis habilidades y liderazgo técnico. Esto me ha permitido poder colaborar en diferentes proyectos de gran escala; aportando ideas, apoyo y soluciones.</p>
      <p>Tengo experiencia con diferentes tecnologías como: Linux, Go, Javascript, Typescript, Bash, PHP, etc. Me interesa trabajar profesionalmente con Elixir y Rust.</p>
    </article>
  </section>

  <section id="skills">
    <header>
      <h2>Skills</h2>
    </header>
    <ul>
      <li *ngFor="let skill of skills">
        {{ skill.nombre }} <span>({{ skill.nivel }})</span>
      </li>
    </ul>
  </section>

  <section id="experience">
    <header>
      <h2>Experiencia</h2>
    </header>
    <ng-container *ngFor="let experience of jobExperience">
      <app-job-experience [experience]="experience"></app-job-experience>
    </ng-container>
  </section>

  <section id="foss">
    <header>
      <h2>Open Source</h2>
    </header>

    <article>
      <ul>
        <li><span>Go Credit Card</span> Biblioteca de Go para validación de tarjetas de crédito utilizando el algoritmo de Luhn.</li>
        <li><span>Gifsy</span> Script de Bash para crear una animación con efecto de glitch a través de una imagen.</li>
        <li><span>Gin Examples</span> Repositorio oficial de ejemplos del framework Gin.</li>
        <li><span>Glitch</span> Software escrito en Golang para crear arte generativo utilizando imágenes.</li>
        <li><span>NES.css</span> Framework CSS con aspecto de videojuegos de la consola Nintendo NES.</li>
      </ul>
    </article>
  </section>

  <section id="volunteering">
    <header>
      <h2>Voluntariado</h2>
    </header>
    <article>
      <header>
        <h3>Laboratoria</h3>
        <time datetime="2019-08-01">2019</time> - Actual
        <h4>Mentor</h4>
      </header>
      <p>Realizar entrevistas técnicas de práctica a las estudiantes y apoyarlas con documentación o tips en caso de que lo requieran.</p>
    </article>
    <article>
      <header>
        <h3>FLISOL</h3>
        <time datetime="2015-01-01">2014</time> - <time datetime="2018-01-01">2018</time>
        <h4>Staff</h4>
      </header>
      <p>Ser parte del staff de los eventos, realizar instalaciones de sistemas operativos y compartir software libre para sistemas operativos privativos.</p>
    </article>
  </section>

  <section id="languages">
    <header>
      <h2>Idiomas</h2>
    </header>

    <article>
      <header>
        <h3>Español</h3>
        <h4>(Materno)</h4>
      </header>
    </article>
    <article>
      <header>
        <h3>Inglés</h3>
        <h4>(Intermedio)</h4>
      </header>
    </article>
    <article>
      <header>
        <h3>Japonés</h3>
        <h4>(Básico)</h4>
      </header>
    </article>
  </section>

  <section id="education">
    <header>
      <h2>Educación</h2>
    </header>

    <article>
      <header>
        <h3>IPN</h3>
        <time datetime="2015-08-01">2015</time> - <time datetime="2017-01-01">2017</time>
        <h4>Ingeniería en computación</h4>
      </header>
    </article>
  </section>

</main>
